import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  { 
    path: '/', 
    redirect: '/home'
  },
  { 
    name: 'Login',
    path: '/login', 
    component: ()=>import('../components/Login.vue'), 
    meta: {
      title: 'Young Scientist Association'
    }
  },
  {
    name: 'Home',
    path: '/home', 
    component: () => import('../views/Home.vue'), 
    title: 'new web site....',  // 新主页
    meta: {
      title: 'Young Scientist Association'
    },
    children: [
    ]
  },
  {
    // name: 'Test',
    path: '/test',
    component: ()=>import('../views/test.vue')
  },
  {
    // name: 'Test',
    path: '/v1i1',
    component: ()=>import('../views/TYS/v1i1.vue'),
    meta: {
      title: 'Volume 1(1) | The Young Scientist'
    }
  },
  {
    // name: 'Test',
    path: '/v2i1',
    component: ()=>import('../views/TYS/v2i1.vue'),
    meta: {
      title: 'Volume 2(1) | The Young Scientist'
    }
  },
  {
    // name: 'Test',
    path: '/v3i1',
    component: ()=>import('../views/TYS/v3i1.vue'),
    meta: {
      title: 'Volume 3(1) | The Young Scientist'
    }
  },
  {
    // name: 'How to submit',
    path: '/Howto',
    component: ()=>import('../views/Howto.vue'),
    meta: {
      title: 'How to submit | YSA'
    }
  },
  {
    // name: 'Our Mission',
    path: '/OurMission',
    component: ()=>import('../views/LogoMission.vue'),
    meta: {
      title: 'Our Mission | YSA'
    }
  },
  {
    // name: 'EditorialList',
    path: '/EditorialList',
    component: ()=>import('../views/EditorialList.vue'),
    meta: {
      title: 'Editorial List | YSA'
    }
  },
  {
    // name: 'EditorialList',
    path: '/Database',
    component: ()=>import('../views/Database.vue'),
    meta: {
      title: 'Database | YSA'
    }
  },
  {
    // name: 'EditorialList',
    path: '/Soil',
    component: ()=>import('../views/Soil/Soil.vue'),
    meta: {
      title: 'Soil | UN-SDG'
    }
  },
  {
    path: '/forauthors',
    component: ()=>import('../views/forauthors.vue'),
    meta: {
      title: 'For Authors | YSA'
    }
  },
]

const router = new VueRouter({
  routes
})

// 挂在路由导航卫士
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  window.document.title = to.meta.title == undefined?'YSA 2023':to.meta.title

  // if (to.path === '/camp')
  //   document.title = 'PBL 科创营 – Shenzhen Xcube Technology Limited'
  // else
  //   document.title = '趣方科技( Xcube-Tech Console )'
  // // 如果是网页 直接放行
  // if (to.path.includes('/header') || to.path === '/camp' || to.path === '/login' || to.path === '/practice' || to.path === '/plankton' || to.path==='/test') {
  //   if(to.path==='/plankton')      
  //     document.title = 'Daphina Technology 2022'
  //   return next();
  // }
  return next();
})

export default router
